import React, {useEffect, useState} from "react";
import MyModal from "../UI/MyModal/MyModal";
import {useNavigate} from "react-router-dom";
import { Helmet } from "react-helmet";

const Main=()=>{

    const router = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    useEffect(()=>{
        window.addEventListener("resize", ()=>{
            setWidth(window.innerWidth);
        }); 
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
    },[])
    return(
        <div className="main">
            <Helmet>
                <title>Ремонт Побутової Техніки (Київ). Виклик Майстра Додому</title>
                <meta name="description" content="Виїзд майстра з ремонту побутової техніки по Києву за вашою адресою. Домовитися можна попередньо за телефоном (063)340-8925"/>
            </Helmet>
            <MyModal visible={visible} setVisible={setVisible}>{width}</MyModal>
            <div className="main-block1">
                <div className="block1-top">
                    <p className="block1-top-p">Наші послуги</p>
                </div>
                <div className="block1-middle">
                    <div className="item">
                        <img className="item__image" src="/images/refrigerator.webp" alt="refrigerator" />
                        <p className="item__title">Ремонт холодильників</p>
                        <p className="item__about break cancel">Проблеми з холодильником?
                        Недостатньо охолоджує, занадто довго працює без відпочинку?
                        Ми виявимо проблему та налагодимо бездоганну роботу вашого холодильника.</p>
                        <p className="item__more" onClick={()=>{router("/refrigerator")}}>Дізнатися більше</p>
                    </div>
                    <div className="item">
                        <img className="item__image" src="/images/laundry.webp" alt="washing machine" />
                        <p className="item__title">Ремонт пральних машин</p>
                        <p className="item__about break cancel">Ваша пральна машина не відкачує воду?
                        Можливо, забилась фільтраційна система або не працює помпа.
                        Ми відремонтуємо вашу пральну машину і вона буде допомагати вам, як і раніше.</p>
                        <p className="item__more" onClick={()=>{router("/washing-machine")}}>Дізнатися більше</p>
                    </div>
                    <div className="item">
                        <img className="item__image" src="/images/condition.webp" alt="air-condition" />
                        <p className="item__title">Ремонт кондиціонерів</p>
                        <p className="item__about break cancel">Кондиціонер працює, але не охолоджує?
                        Скоріш за все в системі недостатньо фреону. Ми все перевіримо,
                        якщо й справді не вистачає фреону, ми заправимо фреон та виявимо утечу.</p>
                        <p className="item__more" onClick={()=>{router("/air-condition")}}>Дізнатися більше</p>
                    </div>
                </div>
                <div className="block1-featuresdiv">
                    <p className="block1-features">Наші переваги</p>
                </div>
                <div className="block1-last">
                    <ul className="block1-last__list">
                        <li className="block1-last__list-item">Можливість обговорити проблему до виїзду.</li>
                        <li className="block1-last__list-item">Швидко та якісно вирішуємо проблему.</li>
                        <li className="block1-last__list-item">Багаторічний досвід у галузі ремонту.</li>
                    </ul>
                </div>   
            </div>
        </div>
    )
}

export default Main;