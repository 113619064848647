import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar =()=>{

    const router = useNavigate();

    return(
        <div className="navbar">
            <p className="main__title" onClick={()=>{router("/main")}}>Ремонт техніки</p>
            <div className="navbar__info">
                <a className="tel" href="tel: +380633408925">+38(063)340-8925</a>
                <div className="navbar__sn">
                    <p className="city">м. Київ</p>
                    <a className="snlink" target="_blank" rel="noopener noreferrer" href="https://t.me/+380633408925">
                        <img className="tg" src="/images/telegram.webp" alt="telegram" />
                    </a>
                </div>
            </div>
        </div>
    )
}


export default Navbar;