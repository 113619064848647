import React, {useEffect} from "react";
import { Helmet } from "react-helmet";

const Refrigerator=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <div className="page">
            <Helmet>
                <title>Ремонт Холодильників (Київ). Виклик Майстра Додому</title>
                <meta name="description" content="Виїзд майстра з ремонту холодильників по Києву за вашою адресою. Домовитися можна попередньо за телефоном (063)340-8925"/>
            </Helmet>
            <p className="page__title">Ремонт холодильників</p>
            <img className="page__img" src="images/refrigerator.webp" alt="refrigerator" />
            <div className="page-top">
                <p className="page-top__p">Послуги</p>
            </div>
            <div className="block1-last">
                <ul className="block1-last__list">
                <li className="block1-last__list-item break cancel">Пошук витоку фреону.</li>
                    <li className="block1-last__list-item break cancel">Заміна фреону.</li>
                    <li className="block1-last__list-item break cancel">Заміна компресора.</li>
                    <li className="block1-last__list-item break cancel">Заміна фільтруючого елемента.</li>
                    <li className="block1-last__list-item break cancel">Заміна конденсатора.</li>
                    <li className="block1-last__list-item break cancel">Ремонт плати керування.</li>
                    <li className="block1-last__list-item break cancel">Заміна терморегулятора.</li>
                    <li className="block1-last__list-item break cancel">Заміна пускового реле.</li>
                </ul>
            </div>
        </div>
    )
}

export default Refrigerator;