import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Ac from "../pages/Ac";
import Main from "../pages/Main";
import WashMach from "../pages/WashMach";
import Refrigerator from "../pages/Refrigerator";

const AppRouter=()=>{
    return(
        <Routes>
            <Route exact path="*" element={<Navigate to="/home"/>} />
            <Route exact path="/air-condition" element={<Ac/>}/>
            <Route exact path="/refrigerator" element={<Refrigerator/>}/>
            <Route exact path="/washing-machine" element={<WashMach/>}/>
            <Route exact path="/home" element={<Main/>} />
        </Routes>
    )
}

export default AppRouter;