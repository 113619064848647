import React, {useEffect} from "react";
import { Helmet } from "react-helmet";

const Ac=()=>{

    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      },[])

    return(
        <div className="page">
            <Helmet>
                <title>Ремонт Кондиціонерів (Київ). Виклик Майстра Додому</title>
                <meta name="description" content="Виїзд майстра з ремонту кондиціонерів по Києву за вашою адресою. Домовитися можна попередньо за телефоном (063)340-8925"/>
            </Helmet>
            <p className="page__title">Ремонт кондиціонерів</p>
            <img className="page__img" src="images/condition.webp" alt="ac" />
            <div className="page-top">
                <p className="page-top__p">Послуги</p>
            </div>
            <div className="block1-last">
                <ul className="block1-last__list">
                <li className="block1-last__list-item break cancel">Знезараження.</li>
                    <li className="block1-last__list-item break cancel">Промивка і чистка кондиціонера від грибка та пилу.</li>
                    <li className="block1-last__list-item break cancel">Чистка зовнішнього блоку від пилу та бруду.</li>
                    <li className="block1-last__list-item break cancel">Пошук та усунення витоку фреону.</li>
                    <li className="block1-last__list-item break cancel">Заправка кондиціонера фреоном.</li>
                    <li className="block1-last__list-item break cancel">Заміна компресора.</li>
                    <li className="block1-last__list-item break cancel">Ремонт плати керування.</li>
                </ul>
            </div>
        </div>
    )
}

export default Ac;